<template>
  <div class="order_page">
    <div
      style="
        box-shadow: 10px 10px 10px rgb(0 0 0 / 35%);
        background: #fff;
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
      "
    >
      <div class="page_title">{{ language.TransactionList }}</div>
      <div style="margin-top: 20px">
        <div style="display: flex; flex-wrap: wrap">
          <!-- 订单号 -->
          <div class="input_box input_box2">
            <el-input
              v-model="Order_Number"
              :placeholder="language.Merchantordernumber"
            ></el-input>
          </div>
          <!-- EPAY订单号 -->
          <div class="input_box input_box2">
            <el-input
              v-model="EPAYOrder_Number"
              :placeholder="'EPAY ' + language.orderNumber"
            ></el-input>
          </div>
          <!-- 应用编号  appid-->
          <div class="input_box input_box2">
            <el-input v-model="appid" :placeholder="language.Apply_No"></el-input>
          </div>
          <!-- 商户名称 -->
          <!-- <div class="input_box">
                    <el-select v-model="Merchant_Name" :placeholder="language.Merchant_Name">
                        <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div> -->
          <!-- 用户账号 -->
          <!-- <div class="input_box">
                    <el-input v-model="User_Account" :placeholder="language.User_Account"></el-input>
                </div> -->
          <!-- 交易渠道 -->
          <div class="input_box input_box2">
            <el-select
              v-model="TransactionChannel"
              :placeholder="language.TransactionChannel"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 币种 -->
          <div class="input_box input_box2">
            <el-select v-model="Currency" :placeholder="language.Currency">
              <el-option
                v-for="item in Currencylist"
                :key="item.type_name"
                :label="item.type_name"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <!-- </div>
            <div style="display: flex;margin-top: 30px;"> -->
          <!-- 交易时间 -->
          <div class="input_box">
            <el-date-picker
              v-model="create_time"
              type="daterange"
              :range-separator="language.to"
              :start-placeholder="language.Start_Date"
              :end-placeholder="language.End_Date"
            >
            </el-date-picker>
          </div>
          <!-- 来源 -->
          <div class="input_box input_box2">
            <el-select v-model="Source" :placeholder="language.Source">
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 支付状态 -->
          <div class="input_box input_box2">
            <el-select v-model="Payment_Status" :placeholder="language.Payment_Status">
              <el-option
                v-for="item in options3"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 查询 -->
          <div class="input_box">
            <el-button type="primary" id="SearchBtn" @click="SearchBtn()">{{
              language.Search
            }}</el-button>
          </div>
          <!-- 重置时间 -->
          <div class="input_box">
            <el-button type="danger" id="ResetBtn" @click="ResetBtn()">{{
              language.Reset
            }}</el-button>
          </div>
          <!-- 打印 -->
          <div
            class="input_box Export_box"
            :style="Exporttype == 2 ? 'min-width:200px' : ''"
          >
            <el-button v-if="Exporttype == 1" type="success" @click="ExportBnt()">{{
              language.Export
            }}</el-button>
            <el-progress
              v-if="Exporttype == 2"
              style="width: 100%"
              :percentage="percentage"
              :show-text="false"
            ></el-progress>
            <download-excel
              v-if="Exporttype == 3"
              class="export-excel-wrapper"
              :data="json_table"
              :fields="json_fields"
              type="txt"
              :name="ordername + '.xls'"
            >
              <el-button type="success">{{ language.Download_Bill }}</el-button>
            </download-excel>
          </div>
        </div>
      </div>
      <div>
        <div class="search_last_box">
          <!-- 已支付金额 -->
          <div v-if="showCurrency">
            <div>{{ language.Paidamount }}</div>
            <div>{{ yesPayValue }}{{ showCurrency }}</div>
          </div>
          <!-- 未支付金额 -->
          <!-- <div>
            <div>{{ language.Unpaidamount }}</div>
            <div>{{ notPayValue }}{{ currency }}</div>
          </div> -->
        </div>
        <div style="padding: 10px 10px 10px 0">
          <div
            style="
              width: 26px;
              height: 26px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #2c3e50;
              cursor: pointer;
            "
            @click="init()"
          >
            <img style="width: 16px" src="../../static/Refresh.png" alt="" />
          </div>
        </div>
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          stripe
          v-loading="loading"
          :header-cell-style="{ background: '#fafafa' }"
        >
          <!-- 商户订单号 -->
          <el-table-column
            prop="mch_order_no"
            :label="language.Merchantordernumber"
            min-width="10%"
          >
          </el-table-column>
          <!-- epay订单号 -->
          <el-table-column
            prop="my_order_no"
            :label="'EPAY ' + language.orderNumber"
            min-width="10%"
          >
          </el-table-column>
          <!-- 商品名 -->
          <el-table-column
            prop="order_goods_name"
            :label="language.TradeName"
            min-width="10%"
          >
          </el-table-column>
          <!-- appid -->
          <!-- <el-table-column
            prop="appid"
            :label="language.Apply_No + '/appId'"
            min-width="10%"
          >
          </el-table-column> -->
          <!-- 交易渠道 -->
          <el-table-column :label="language.TransactionChannel" min-width="10%">
            <template slot-scope="scope">
              <div v-for="(item, index) in Paymentchannellist2" :key="index">
                <span v-if="item.value == scope.row.pay_from_id">{{ item.label }}</span>
              </div>
            </template>
          </el-table-column>
          <!-- usdt地址 -->
          <el-table-column :label="'USDT' + language.Address" min-width="10%">
            <template slot-scope="scope" v-if="scope.row.usdt_data">
              <span>{{ JSON.parse(scope.row.usdt_data).tar_addr }} </span>
            </template>
          </el-table-column>
          <!-- type usdt方式 -->
          <el-table-column :label="'USDT' + language.agreement" min-width="10%">
            <template slot-scope="scope" v-if="scope.row.usdt_channel">
              <span>{{ scope.row.usdt_channel }} </span>
            </template>
          </el-table-column>
          <!-- 订单金额 -->
          <el-table-column :label="language.Amount" min-width="10%">
            <template slot-scope="scope">
              <span>{{ scope.row.money }} </span>
              <span>{{ scope.row.currency }} </span>
            </template>
          </el-table-column>
          <!-- 实际支付金额 -->
          <el-table-column :label="language.Actualpaymentamount" min-width="10%">
            <template slot-scope="scope">
              <span>{{ scope.row.pay_amount }} </span>
              <span>{{ scope.row.currency }} </span>
            </template>
          </el-table-column>
          <!-- 手续费 -->
          <el-table-column :label="language.ServiceCharge" min-width="10%">
            <template slot-scope="scope">
              <span>{{ scope.row.service_fee }} </span>
              <span>{{ scope.row.currency }} </span>
            </template>
          </el-table-column>
          <!-- 商户结算金额 -->
          <el-table-column :label="language.Merchantsettlementamount" min-width="10%">
            <template slot-scope="scope">
              <span>{{ scope.row.settle_amount }} </span>
              <span>{{ scope.row.currency }} </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="create_time"
            :label="language.Creation_time"
            min-width="10%"
          >
          </el-table-column>
          <el-table-column :label="language.PaymentTime" min-width="10%">
            <template slot-scope="scope">
              <span v-if="scope.row.pay_server_time">{{
                $getNowFormatDate.chagetime(scope.row.pay_server_time)
              }}</span>
              <span v-else></span>
            </template>
          </el-table-column>
          <el-table-column :label="language.Source" min-width="10%">
            <template slot-scope="scope">
              <span v-if="scope.row.pay_scene == 0">{{ language.Mobilewebpage }}</span>
              <span v-if="scope.row.pay_scene == 1">{{ language.officialaccount }}</span>
              <span v-if="scope.row.pay_scene == 2">{{ language.Applets }}</span>
              <span v-if="scope.row.pay_scene == 3">{{ language.Computerwebsite }}</span>
            </template>
            <!-- <template>
                            {{language.Mobilewebpage}}
                        </template> -->
          </el-table-column>
          <el-table-column :label="language.State" min-width="10%">
            <!-- pay_state -->
            <template slot-scope="scope">
              <span v-if="scope.row.pay_state == 0" style="color: red">{{
                language.Unpaid
              }}</span>
              <span v-if="scope.row.pay_state == 1" style="color: #dd9410">{{
                language.Paidbutnotreceived
              }}</span>
              <span v-if="scope.row.pay_state == 2" style="color: #009b05">{{
                language.Paidandreceived
              }}</span>
              <span v-if="scope.row.pay_state == 3" style="color: red">{{
                language.Canceled
              }}</span>
            </template>
          </el-table-column>
          <!-- 操作 -->
          <el-table-column :label="language.Operation" min-width="15%">
            <!-- pay_state -->
            <!-- 详情 -->
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="Details(scope.row)">{{
                language.Details
              }}</el-button>
              <!-- 退款 -->
              <!-- <el-button size="mini" type="warning" @click="refund(scope.row)">{{
                language.refund
              }}</el-button> -->
            </template>
          </el-table-column>

          <!-- <el-table-column :label="language.callbacknotification" min-width="10%">
            <template slot-scope="scope">
              <span
                style="color: #009b05"
                v-if="scope.row.is_notify && scope.row.pay_state > 0"
                >{{ language.success }}</span
              >
              <span
                style="color: red"
                v-if="!scope.row.is_notify && scope.row.pay_state > 0"
                >{{ language.bedefeated }}</span
              >
              <span v-if="scope.row.pay_state == 0">/</span>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
      <!-- 退款 refund -->
      <el-dialog :title="language.refund" :visible.sync="refunddialogVisible" width="60%">
        <!-- 商户订单号 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.Merchantordernumber }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.mch_order_no" disabled></el-input>
          </div>
        </div>
        <!-- EPAY订单号 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            EPAY {{ language.orderNumber }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.my_order_no" disabled></el-input>
          </div>
        </div>
        <!-- 商品名称 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.TradeName }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.order_goods_name" disabled></el-input>
          </div>
        </div>
        <!-- 实际支付金额 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.Actualpaymentamount }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.pay_amount" disabled></el-input>
          </div>
        </div>
        <!-- 创建时间 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.Creation_time }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.create_time" disabled></el-input>
          </div>
        </div>
        <!-- 可退款金额 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.Refundableamount }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.pay_amount" disabled></el-input>
          </div>
        </div>
        <!-- 本次退款金额 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.Currentrefundamount }}:
          </div>
          <div style="flex: 1">
            <el-input
              :placeholder="language.Currentrefundamount"
              v-model="Currentrefundamount"
            ></el-input>
          </div>
        </div>
        <!-- 退款原因 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.Reasonforrefund }}:
          </div>
          <div style="flex: 1">
            <el-input
              :placeholder="language.Reasonforrefund"
              v-model="Reasonforrefund"
            ></el-input>
          </div>
        </div>
        <!-- 安全验证 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.Securityverification }}:
          </div>
          <div style="flex: 1">
            <el-input
              :placeholder="language.Operationpassword"
              v-model="Securityverification"
            ></el-input>
          </div>
        </div>
        <div style="display: flex; justify-content: center; margin-top: 22px">
          <el-button type="primary">{{ language.Submitarefundrequest }}</el-button>
        </div>
      </el-dialog>
      <!-- 详情 -->
      <el-dialog
        :title="language.Details"
        :visible.sync="DetailsdialogVisible"
        width="60%"
      >
        <!-- 创建时间 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.Creation_time }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.create_time" disabled></el-input>
          </div>
        </div>
        <!-- 支付时间 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.PaymentTime }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.pay_server_time" disabled></el-input>
          </div>
        </div>
        <!-- 状态 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.State }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.pay_state" disabled></el-input>
          </div>
        </div>
        <!-- 商户订单号 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.Merchantordernumber }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.mch_order_no" disabled></el-input>
          </div>
        </div>
        <!-- EPAY订单号 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            EPAY {{ language.orderNumber }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.my_order_no" disabled></el-input>
          </div>
        </div>
        <!-- 商品名称 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.TradeName }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.order_goods_name" disabled></el-input>
          </div>
        </div>
        <!-- 应用编号appid -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.Apply_No }}/AppId:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.appid" disabled></el-input>
          </div>
        </div>
        <!-- 交易渠道 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.TransactionChannel }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.pay_from_id" disabled></el-input>
          </div>
        </div>
        <!-- 订单金额 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.Amount }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.money" disabled></el-input>
          </div>
        </div>
        <!-- 实际支付金额 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.Actualpaymentamount }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.pay_amount" disabled></el-input>
          </div>
        </div>
        <!-- 交易手续费 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.ServiceCharge }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.service_fee" disabled></el-input>
          </div>
        </div>
        <!-- 商户结算金额 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.Merchantsettlementamount }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.settle_amount" disabled></el-input>
          </div>
        </div>
        <!-- USDT地址 -->
        <div class="msgbox" v-if="msg.usdt_data">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            USDT{{ language.Address }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="JSON.parse(msg.usdt_data).tar_addr" disabled></el-input>
          </div>
        </div>
        <!-- USDT协定 -->
        <div class="msgbox" v-if="msg.usdt_data">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            USDT{{ language.agreement }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.usdt_channel" disabled></el-input>
          </div>
        </div>
        <!-- 回档通知 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.callbacknotification }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.callbacknotification" disabled></el-input>
          </div>
        </div>
        <!-- 来源 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.Source }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.pay_scene" disabled></el-input>
          </div>
        </div>
      </el-dialog>
      <div>
        <div class="paging">
          <el-pagination
            v-loading="loading"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            current-page.sync="5"
            :page-sizes="[10, 15, 20, 25, 30]"
            :page-size="limit"
            layout="sizes, prev, pager, next"
            :total="pages"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import payment from "../../common/json/payment.json";
import { order } from "../../api/index.js";
export default {
  data() {
    return {
      languagetype: "",
      language: "",
      searchmsg: {},
      DetailsdialogVisible: false,
      refunddialogVisible: false,
      showCurrency: "",
      options: [
        {
          value: "-1",
          label: "",
        },
      ],
      options3: [
        {
          //支付状态
          value: "-1",
          label: "", //全部
        },
        {
          //支付状态
          value: "0",
          label: "", //未支付
        },
        {
          value: "1",
          label: "", //已支付未回调
        },
        {
          value: "2",
          label: "", //支付成功
        },
      ],
      options2: [
        {
          value: "-1",
          label: "",
        },
        {
          value: "0",
          label: "",
        },
        {
          value: "1",
          label: "",
        },
        {
          value: "2",
          label: "",
        },
        {
          value: "3",
          label: "",
        },
      ],
      isDch: 0,
      tableData: [],
      json_fields: {
        // "名称": "name",//常规字段
        // "Telephone": "phone.mobile", //支持嵌套属性
      },
      json_table: [],
      json_meta: [
        [
          {
            " key ": " charset ",
            " value ": " utf- 8 ",
          },
        ],
      ],
      Order_Number: null, //订单号
      EPAYOrder_Number: "", //EPAY订单号
      Merchant_Name: null, //商户名称
      User_Account: null, //用户账号
      TransactionChannel: null, //交易渠道
      create_time: null, //交易时间
      searchtime: null, //格式时间
      Source: null, //来源
      Payment_Status: null, //支付状态
      appid: null,
      showlimit: 10,
      pages: 1,
      page: 1,
      limit: 15,
      loading: false,
      Exporttype: 1,
      Exportpage: 0, //导出页数
      Exportpageing: 0, //已导出的页数
      Exporttrue: false,
      percentage: 0, //到处百分百
      ordername: "",
      Currency: "", //币种
      Paymentchannellist: "", //渠道
      Paymentchannellist2: "", //渠道
      Currencylist: [
        {
          type_name: "",
          value: "",
        },
      ],
      userinfo: "",
      msg: "", //订单详情
      yesPayValue: "",
      Currentrefundamount: "", //退款金额
      Reasonforrefund: "", //退款原因
      Securityverification: "", //安全验证
    };
  },
  created() {
    this.Currencylist = [...this.Currencylist, ...payment.otherCurrencylist];
    this.options = [...this.options, ...payment.Paymentmethod];
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    if (this.userinfo.currency != "all") {
      for (let i = this.Currencylist.length - 1; i >= 0; i--) {
        if (this.Currencylist[i].value != this.userinfo.currency) {
          this.Currencylist.splice(i, 1);
        }
      }
    }
    this.changelanguage();
    if (this.Currencylist[0]) {
      this.Currency = this.Currencylist[0];
    }
    this.init();
  },
  watch: {
    "$store.state.language"() {
      this.changelanguage();
    },
    Exporttrue() {
      if (this.Exporttrue) {
        if (this.Exportpageing < this.Exportpage) {
          this.Exportpageing = this.Exportpageing + 1;
          this.Exporttrue = false;
          this.forExport();
        }
      }
    },
  },

  methods: {
    // 获取数据
    init() {
      this.tableData = [];
      this.loading = true;
      let data = {
        page: this.page,
        limit: this.limit,
      };
      if (this.Order_Number) {
        data.mchOrder = this.Order_Number;
      }
      if (this.create_time) {
        data.datetime = this.searchtime;
      }
      if (this.User_Account) {
        data.myOrder = this.User_Account;
      }
      if (this.Payment_Status >= 0 && this.Payment_Status != null) {
        data.payState = Number(this.Payment_Status);
      }
      if (this.Currency.value != -1) {
        data.currency = this.Currency.value;
      }
      if (this.appid) {
        data.appid = this.appid;
      }
      if (this.Source >= 0 && this.Source != null) {
        data.scene = this.Source;
      }
      if (this.TransactionChannel >= 0 && this.TransactionChannel != null) {
        data.from = this.TransactionChannel;
      }
      if (this.EPAYOrder_Number) {
        data.myOrder = this.EPAYOrder_Number;
      }
      data.isDch = this.isDch;
      order({}, data).then((res) => {
        if (res.code == 1) {
          this.showCurrency = this.Currency.value;
          this.loading = false;
          this.tableData = res.data.list;
          this.pages = res.data.count;
          this.yesPayValue = res.data.yesPayValue;
        }
      });
    },
    //搜索
    SearchBtn() {
      document.getElementById("SearchBtn").blur();
      this.Exporttype = 1;
      this.page = 1;
      this.pages = 1;
      if (this.create_time) {
        this.searchtime =
          this.$getNowFormatDate.getNowFormatDate(this.create_time[0]) +
          " 00:00:00 - " +
          this.$getNowFormatDate.getNowFormatDate(this.create_time[1]) +
          " 23:59:59";
      }
      this.init();
    },
    //重置
    ResetBtn() {
      document.getElementById("ResetBtn").blur();
      this.page = 1;
      this.pages = 1;
      this.Exporttype = 1;
      this.Currency = "";
      this.EPAYOrder_Number = "";
      this.create_time = null;
      this.Order_Number = null;
      this.User_Account = null;
      this.appid = null;
      this.Payment_Status = null;
      this.TransactionChannel = null;
      this.Source = null;
      this.init();
    },
    // 切换每页条数
    handleSizeChange(val) {
      this.page = 1;
      this.limit = val;
      this.init();
    },
    //选择页数
    handleCurrentChange(val) {
      this.page = val;
      this.init();
    },
    // 退款
    refund(e) {
      this.refunddialogVisible = true;
      this.msg = JSON.parse(JSON.stringify(e));
      console.log(e);
    },
    // 查看详情
    Details(e) {
      this.DetailsdialogVisible = true;
      this.msg = JSON.parse(JSON.stringify(e));
      if (this.msg.pay_server_time) {
        this.msg.pay_server_time = this.$getNowFormatDate.chagetime(
          this.msg.pay_server_time
        );
      }
      if (this.msg.pay_state == 0) {
        this.msg.pay_state = this.language.Unpaid;
      } else if (this.msg.pay_state == 1) {
        this.msg.pay_state = this.language.Paidbutnotreceived;
      } else if (this.msg.pay_state == 2) {
        this.msg.pay_state = this.language.Paidandreceived;
      } else if (this.msg.pay_state == 3) {
        this.msg.pay_state = this.Canceled.Unpaid;
      }
      // 渠道名
      if (this.msg.pay_from_id == "-1") {
        this.msg.pay_from_id = "";
      } else {
        this.Paymentchannellist.map((item) => {
          if (item.value == this.msg.pay_from_id) {
            this.msg.pay_from_id = item.label;
          }
        });
      }
      this.msg.money = this.msg.money + this.msg.currency;
      this.msg.pay_amount = this.msg.pay_amount + this.msg.currency;
      this.msg.service_fee = this.msg.service_fee + this.msg.currency;
      this.msg.settle_amount = this.msg.settle_amount + this.msg.currency;
      if (this.msg.is_notify && this.msg.pay_state > 0) {
        this.msg.callbacknotification = this.language.success;
      } else if (this.msg.is_notify && this.msg.pay_state > 0) {
        this.msg.callbacknotification = this.language.bedefeated;
      } else {
        this.msg.callbacknotification = "/";
      }
      if (this.msg.pay_scene == 0) {
        this.msg.pay_scene = this.language.Mobilewebpage;
      } else if (this.msg.pay_scene == 1) {
        this.msg.pay_scene = this.language.officialaccount;
      } else if (this.msg.pay_scene == 2) {
        this.msg.pay_scene = this.language.Applets;
      } else if (this.msg.pay_scene == 3) {
        this.msg.pay_scene = this.language.Computerwebsite;
      }
    },
    // 获取语言
    changelanguage() {
      this.languagetype = this.$store.state.language;
      this.language = this.$units();
      this.Currencylist[0].type_name = this.language.whole;
      this.Paymentchannellist = [...payment.Paymentmethod];
      let msg = [
        {
          label: this.language.Incompleteprocess,
          value: "-1",
        },
      ];
      this.Paymentchannellist2 = [...msg, ...this.Paymentchannellist];
      this.options3[0].label = this.language.whole; //全部
      this.options3[1].label = this.language.Unpaid; //未支付
      this.options3[2].label = this.language.Paidbutnotreceived; //已支付未回调
      this.options3[3].label = this.language.Paidandreceived; //支付成功
      // this.options3[4].label = this.language.Canceled//已取消

      // if (this.userinfo.currency == "all") {
      //   this.Currencylist[0].type_name = this.language.whole; //全部
      // }

      this.options2[0].label = this.language.whole; //全部
      this.options2[1].label = this.language.Mobilewebpage; //手机网页
      this.options2[2].label = this.language.officialaccount; //公众号
      this.options2[3].label = this.language.Applets; //小程序
      this.options2[4].label = this.language.Computerwebsite; //电脑网页

      this.options[0].label = this.language.whole; //全部
      for (let i = 0; i < this.options.length; i++) {
        if (this.options[i].value == 0) {
          this.options[i].label = this.language.Alipay; //支付宝
        }
        if (this.options[i].value == 1) {
          this.options[i].label = this.language.WeChat; //支付宝
        }
      }
      // this.options[1].label = this.language.Alipay; //支付宝
      // this.options[2].label = this.language.WeChat; //微信
      // this.options[3].label = "PayPal"; //PayPal
      // this.options[4].label = "SuerPay"; //SuerPay
      // this.options[4].label = this.language.Onlinebanking //网银
      // this.options[5].label = this.language.UnionPaycode //银联扫码
      // this.options[6].label = this.language.UnionPayExpress //银联快捷

      this.json_fields = {
        [this.language.Merchantordernumber]: {
          field: "mch_order_no",
          callback: (value) => {
            return "&nbsp;" + value;
          },
        },
        ["EPAY " + this.language.orderNumber]: {
          field: "my_order_no",
          callback: (value) => {
            return "&nbsp;" + value;
          },
        },
        [this.language.TradeName]: {
          field: "order_goods_name",
          callback: (value) => {
            return "&nbsp;" + value;
          },
        },
        [this.language.Apply_No + "/appId"]: {
          field: "appid",
          callback: (value) => {
            return "&nbsp;" + value;
          },
        },
        [this.language.TransactionChannel]: "pay_from_id",
        [this.language.Amount]: "money",
        [this.language.Actualpaymentamount]: "pay_amount",
        [this.language.ServiceCharge]: "service_fee",
        [this.language.Merchantsettlementamount]: "settle_amount",
        [this.language.TransactionTime]: "create_time",
        [this.language.PaymentTime]: "pay_server_time",
        [this.language.Source]: "pay_scene",
        [this.language.State]: "pay_state",
      };
    },
    // 到处列表
    ExportBnt() {
      this.$message({
        message: this.language.Exportingpleasedonotjumptothepageorotheroperations,
        type: "warning",
      });
      this.Exportpageing = 1;
      this.Exporttype = 2;
      this.json_table = [];
      this.forExport();
    },
    // 循环到处
    forExport() {
      let data;
      data = {
        page: this.Exportpageing,
        limit: 100,
      };
      this.Exporttrue = false;
      if (this.Order_Number) {
        data.mchOrder = this.Order_Number;
      }
      if (this.create_time) {
        data.datetime = this.searchtime;
      }
      if (this.Currency.value != -1) {
        data.currency = this.Currency.value;
      }
      if (this.Payment_Status >= 0) {
        data.payState = Number(this.Payment_Status);
      }
      if (this.appid) {
        data.appid = this.appid;
      }
      if (this.User_Account) {
        data.myOrder = this.User_Account;
      }
      if (this.Source) {
        data.scene = this.Source;
      }
      if (this.TransactionChannel) {
        data.from = this.TransactionChannel;
      }
      if (this.EPAYOrder_Number) {
        data.myOrder = this.EPAYOrder_Number;
      }
      data.isDch = this.isDch;
      order({}, data).then((res) => {
        if (res.code == 1) {
          if (res.data.count == 0) {
            this.Exporttype = 1;
            this.$message({
              message: this.language.Nodata,
              type: "warning",
            });
            return;
          }
          this.Exportpage = Math.ceil(res.data.count / 100);
          this.Exporttrue = true;
          this.percentage = (this.Exportpageing / this.Exportpage) * 100;
          for (let i = 0; i < res.data.list.length; i++) {
            if (res.data.list[i].pay_scene == 0) {
              res.data.list[i].pay_scene = this.language.Mobilewebpage;
            } else if (res.data.list[i].pay_scene == 1) {
              res.data.list[i].pay_scene = this.language.officialaccount;
            } else if (res.data.list[i].pay_scene == 2) {
              res.data.list[i].pay_scene = this.language.Applets;
            } else if (res.data.list[i].pay_scene == 3) {
              res.data.list[i].pay_scene = this.language.Computerwebsite;
            }

            if (res.data.list[i].pay_state == 0) {
              res.data.list[i].pay_state = this.language.Unpaid;
            } else if (res.data.list[i].pay_state == 1) {
              res.data.list[i].pay_state = this.language.Paidbutnotreceived;
            } else if (res.data.list[i].pay_state == 2) {
              res.data.list[i].pay_state = this.language.Paidandreceived;
            } else if (res.data.list[i].pay_state == 3) {
              res.data.list[i].pay_state = this.language.Canceled;
            }

            for (let j = 0; j < this.Paymentchannellist2.length; j++) {
              if (res.data.list[i].pay_from_id == this.Paymentchannellist2[j].value) {
                res.data.list[i].pay_from_id = this.Paymentchannellist2[j].label;
              }
            }

            res.data.list[i].money =
              res.data.list[i].money + " " + res.data.list[i].currency;
            res.data.list[i].pay_amount =
              res.data.list[i].pay_amount + " " + res.data.list[i].currency;
            res.data.list[i].service_fee =
              res.data.list[i].service_fee + " " + res.data.list[i].currency;
            if (res.data.list[i].pay_server_time) {
              res.data.list[i].pay_server_time = this.$getNowFormatDate.chagetime(
                res.data.list[i].pay_server_time
              );
            }
          }

          //
          this.json_table = [...this.json_table, ...res.data.list];

          if (this.Exportpage == this.Exportpageing) {
            this.ordername = "Order" + this.$getNowFormatDate.getNowtime(new Date());
            this.$nextTick(() => {
              this.Exporttype = 3;
            });
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.order_page {
  min-height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.order_page > div {
  min-height: calc(100vh - 129px);
}

.page_title {
  font-size: 26px;
  font-weight: 600;
}

.input_box {
  margin-right: 30px;
  margin-top: 10px;
}
.input_box2 {
  width: 200px;
}

.paging {
  width: 100%;
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
}
.search_last_box {
  margin-top: 10px;
  display: flex;
  /* padding: 10px 10px 0 10px; */
  box-sizing: border-box;
  background-color: rgba(242, 242, 242, 1);
}

.search_last_box > div {
  /* width: 300px; */
  /* background-color: rgba(242, 242, 242, 1); */
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.search_last_box > div:last-child {
  margin-left: 20px;
}

.search_last_box > div > div:first-child {
  font-family: cursive;
  font-weight: 700;
  color: #333333;
}

.search_last_box > div > div:last-child {
  /* margin-top: 6px; */
  font-weight: 700;
  font-family: cursive;
  margin-left: 10px;
  color: #ff5722;
}
.msgbox {
  display: flex;
  align-items: center;
}
.msgbox:not(:first-child) {
  margin-top: 20px;
}
* /deep/ .el-table .cell {
  white-space: nowrap;
}

.Export_box {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
